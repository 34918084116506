import axios from "axios";
axios.default.withCredentials = true;
// const request = require('request-promise');
// var config = require("../config");
const SHA224 = require("sha224");
const apiURL = window.location.origin.includes("http://localhost:")
  ? "http://localhost:4000"
  : window.location.origin.replace("3000", "4000");

// const { promise_to_object_promise } = require("./api_utils");
const { Users, Forgot_username, Forgot_password, Log_out } =
  require("../models/user_models").default;

//https://meet.google.com/ikw-ejhx-hgt
//rewrite function login use axios instead of request-promise to make the request for login
function login(wiz_username, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/login/`, {
        wiz_username: wiz_username,
        password: SHA224(password).toString("hex"),
      })
      .then((res) => {
        if (res.data.error !== undefined) {
          resolve(res.data);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function verify_token(token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/verify_token/`, {
        token: token,
      })
      .then((res) => {
        if (res.data.error !== undefined) {
          resolve(res.data);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//logout function using axios to make post request with input of user_id, with headers
function logout(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiURL}/userExpress/logout/`,

        {
          user_id: user_id,
        }
      )
      .then((res) => {
        resolve(new Log_out(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//reset password function using axios to make post request to /restpassword with input of reset_token and new password(use sha224), with headers
function reset(resetToken, password, user_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiURL}/userExpress/resetpassword/`,

        {
          resetToken: resetToken,
          password: password,
          user_id: user_id,
          partnerURL: window.location.origin,
        }
      )
      .then((res) => {
        resolve(new Forgot_password(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//forgot password function using axios to make post request to /forgotpassword with input of wiz_username, with headers
function forgotpassword(wiz_username) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/forgotpassword/`, {
        wiz_username: wiz_username,
        partnerURL: window.location.origin,
      })
      .then((res) => {
        if (res.data.error !== undefined) {
          resolve(res.data);
        }
        resolve(new Forgot_password(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function resetpassword(wiz_username, reset_token_only) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/forgotpassword/`, {
        wiz_username: wiz_username,
        reset_token_only: reset_token_only,
      })
      .then((res) => {
        if (res.data.error !== undefined) {
          resolve(res.data);
        }
        resolve(new Forgot_password(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//forgot username function using axios to make post request to /forgotusername with input of email, with headers
function forgotusername(email) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/forgotusername/`, {
        email: email,
        partnerURL: window.location.origin,
      })
      .then((res) => {
        if (res.data.error !== undefined) {
          resolve(res.data);
        }
        resolve(new Forgot_username(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// sign up function using axios to make post request to /signup
//with input of name, wiz_username,email,password(use sha224), dp_url, usertype, location, is_mobile=false with headers
function signup(
  user_id,
  name,
  wiz_username,
  email,
  password,
  dp_url,
  usertype,
  location,
  is_mobile = false
) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiURL}/userExpress/signup/`,

        {
          user_id: user_id,
          name: name,
          wiz_username: wiz_username,
          email: email,
          password: password,
          dp_url: dp_url,
          usertype: usertype,
          location: location,
          is_mobile: is_mobile,
          partnerURL: window.location.origin,
        }
      )
      .then((res) => {
        resolve(new Users(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function to get user data using axios to make get request to apiURL + "/userExpress/"+user_id+"/" with input of user_id, with headers
function get_user(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/profile/`, { params: { user_id: user_id } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function update_user(param) {
  return new Promise((resolve, reject) => {
    var request_body = {};
    if (param.studentId) {
      request_body["studentId"] = param.studentId;
    }
    if (param.name) {
      request_body["name"] = param.name;
    }

    if (param.wiz_username) {
      request_body["wiz_username"] = param.wiz_username;
    }
    if (param.email) {
      request_body["email"] = param.email;
    }
    axios
      .put(`${apiURL}/userExpress/update_user/`, request_body)
      .then((res) => {
        resolve(new Users(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function get_user_qr_source using axios to make post request to apiURL+/get_user_qr_source/ with input of (user_id) with headers and body (user_id)
function get_user_qr_source(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/qr_code/`, {
        user_id: user_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function list_instructors(user_ids) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/list_instructors/`, {
        user_ids: user_ids,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function points_approval(qr_code_url = -1, points) {
  if (qr_code_url != -1) {
    var approved_user_id = qr_code_url.substring(6, qr_code_url.length);
  } else {
    var approved_user_id = "";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/points_approval`, {
        approved_user_id: approved_user_id,
        qr_code_url: qr_code_url,
        points: points,
      })
      .then((res) => {
        // if (res == "Error" || res == "Success") {
        resolve(res.data);
        // }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function deduct_points using axios to make post request to apiURL+/deduct_points/ with input of (user_id, total_gifts_points) with headers and body (user_id, total_gifts_points),timeout:10000,jason:true
function deduct_points(user_id, total_gifts_points) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/deduct_points`, {
        user_id: user_id,
        total_gifts_points: total_gifts_points,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function list_students(students) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/list_students/`, {
        students: students,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function get_student_log_time(users, class_id) {
  var result = [];

  const singleFile = async (user, class_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiURL}/userExpress/get_student_log_time/`, {
          user_id: user.student_id,
          class_id: class_id,
          level_id: user.student_level_id,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    const file = await singleFile(user, class_id);
    result.push(file);
  }
  return result;
}
async function get_student_log_time2(user_id, class_id, level_id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/get_student_log_time/`, {
        user_id: user_id,
        class_id: class_id,
        level_id: level_id,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function log_session_task_time(user_id, task_id, start_time, end_time) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/log_session_task_time/`, {
        user_id: user_id,
        task_id: task_id,
        start_time: start_time,
        end_time: end_time,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function approve_points_check(qr_code_url, approved_user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/approve_points_check/`, {
        params: {
          qr_code_url: qr_code_url,
          approved_user_id: approved_user_id,
        },
      })
      .then((res) => {
        resolve(new Users(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function redirect(user, next) {
  return new Promise((resolve, reject) => {
    console.log("user", user);
    console.log("next", next);
    axios
      .post(`${apiURL}/userExpress/auth/`, {
        user: user,
        nextLink: next,
      })
      .then((res) => {
        // resolve(new Users(res.data));
        console.log("res", res);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function list_users(user_ids) {
  var ids = user_ids.join(",");
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/list_users/`, {
        params: {
          user_ids: ids,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function get_track_missing_attendance_check(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/get_track_missing_attendance_check/`, {
        params: {
          user_id: user_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// validate_user_info;
function validate_user_info(student_username, student_email) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/validate_user_info/`, {
        params: {
          student_username: student_username,
          student_email: student_email,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err.response.data);
        reject(err.response.data);
      });
  });
}

function get_partner(partnerId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/get_partner/`, {
        params: {
          partnerId: partnerId,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err);
        reject(err);
      });
  });
}

// send_verification_email; usernmae, email
function send_verification_email(username, email) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/send_verification_email/`, {
        username: username,
        email: email,
        partnerURL: window.location.origin,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err.response.data);
        reject(err.response.data);
      });
  });
}

// user_signup_with_partner;
function user_signup_with_partner(SignupBody) {
  SignupBody["partnerURL"] = window.location.origin;
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/user_signup_with_partner/`, SignupBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err.response.data);
        reject(err.response.data);
      });
  });
}

// verify_verification_token;/
function verify_verification_token(username, email, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/userExpress/verify_verification_token/`, {
        username: username,
        email: email,
        token: token,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err.response.data);
        reject(err.response.data);
      });
  });
}

// get_partner_by_origin;
function get_partner_by_origin(partnerUrl) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/get_partner_by_origin/`, {
        params: {
          partnerUrl: partnerUrl,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err);
        reject(err);
      });
  });
}

function session_check() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/userExpress/session_check/`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("err", err);
        reject(err);
      });
  });
}

export default {
  login: login,
  logout: logout,
  reset: reset,
  forgotpassword: forgotpassword,
  forgotusername: forgotusername,
  signup: signup,
  get_user: get_user,
  update_user: update_user,
  get_user_qr_source: get_user_qr_source,
  list_instructors: list_instructors,
  points_approval: points_approval,
  resetpassword: resetpassword,
  deduct_points: deduct_points,
  list_students: list_students,
  get_student_log_time: get_student_log_time,
  get_student_log_time2: get_student_log_time2,
  log_session_task_time: log_session_task_time,
  approve_points_check: approve_points_check,
  redirect: redirect,
  list_users: list_users,
  get_track_missing_attendance_check: get_track_missing_attendance_check,
  verify_token: verify_token,
  validate_user_info: validate_user_info,
  get_partner: get_partner,
  send_verification_email: send_verification_email,
  user_signup_with_partner: user_signup_with_partner,
  verify_verification_token: verify_verification_token,
  get_partner_by_origin: get_partner_by_origin,
  session_check: session_check,
};
