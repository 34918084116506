import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import color from "../../corlorConfig";
import PersonalInfo from "./personalInfo";
import GuardianInfo from "./guardianInfo";
import AreaOfInterest from "./areaOfInterest";
import PartnerCustomField from "./partnerCustomField";
import Logo from "../logo/biglogo.js";
import Confirmation from "./confirmation.js";
import Verify from "./verify.js";
import user_api from "../../ApiCallExpress/user_api.js";
import { useNavigate } from "react-router-dom";
import shared from "../../shared/shareStore.js";
import usertype from "../../usertype.js";

export default function PartnerSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CurrentSignupStep = useSelector(
    (state) => state.partnerSignup_store.CurrentSignupStep
  );
  useEffect(() => {
    // const user = globalStore.getState().session?.user;
    const user = shared.getUser();
    if (user.id) {
      // navigate("/dashboard");
      usertype.studentView.includes(user.userType)
        ? navigate("/dashboard")
        : navigate("/classrooms");
    } else {
      // window reload
      // window.location.reload();
      user_api
        .get_partner_by_origin(window.location.origin)
        .then((res) => {
          console.log(res);
          dispatch({
            type: "SET_PARTNER_INFO",
            payload: res,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [shared.getUser()]);
  return (
    <div className="m-4 md:m-8 px-4 md:px-8 py-4 md:py-8 rounded-xl bg-main">
      {/* logo section */}
      {/* <PartnerCustomField />  */}
      {/* PartnerCustomField will be finished in the future */}
      <div>
        <Logo link="/" />
      </div>
      <div className="">
        {CurrentSignupStep === 1 ? (
          <PersonalInfo />
        ) : CurrentSignupStep === 2 ? (
          <GuardianInfo />
        ) : CurrentSignupStep === 3 ? (
          <AreaOfInterest />
        ) : CurrentSignupStep === 4 ? (
          <Confirmation />
        ) : CurrentSignupStep === 5 ? (
          <Verify></Verify>
        ) : (
          <div></div>
        )}
      </div>
      {/* switch step section */}
    </div>
  );
}
